.innerPageHeader {
  font-family: ClashGrotesk;
}

.innerPageHeader p {
  font-size: 1rem;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: var(--clr-text-light);
}
